<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
               
                     <li class="active a">职位管理</li>
               
                         <li class=" a"><router-link to="/index4/sh44"> 审核</router-link></li>
                </div>
                 <div  class="item">  
                  <li class="a" ><router-link to="/index4/xiaoxi44" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
                 <li style="cursor:pointer;" @click="dialogFormVisible = true">技术总监<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog  class="kk" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <p style="font-size: 22px;

font-weight: 600;
color: #000000;
line-height: 30px;margin:20px 40px">角色管理 <span style="font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 20px;">服务于业务发展的各个角色</span></p>

       <div class="change" style="padding:15px 40px;background:#f5f5f5">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
       
      

      

             <el-tab-pane label="技术专员" name="fourth">
                    <div style="display:flex" class="list"> 
                   </div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">技术专员</p>
                            <el-button
                        size="mini"
                        type='warning'
                        @click="dialogFormVisible1 = true">创建账号</el-button>

                        

                             <el-dialog title="创建技术专员" :visible.sync="dialogFormVisible1" class="cj">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                       <el-form-item label="身份证号码" :label-width="formLabelWidth">
                    <el-input v-model="form1.id" autocomplete="off"></el-input>
                    </el-form-item>
  <el-form-item label="入职时间" :label-width="formLabelWidth">
                                 
                                     <el-date-picker
                                            v-model="form1.worktime"
                                                             format="yyyy 年 MM 月"
                                                                  value-format="yyyy-MM"
                                            type="month"
                                            placeholder="选择月">
                                            </el-date-picker>
                                    </el-form-item>
   
   <div style="display:flex;justify-content: space-between;text-align:center;padding:0 50PX">
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证正面</p>
          
          <img :src="producImg"   style=" margin:10px 0; 
    color: #8c939d;
    width: 178px;
    height: 178px;
      background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;">
    <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input s type="file" name="avatar" style=" margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage" ref="avatarInput"  class="uppic">
       </div>
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证反面</p>
       
          <img :src="producImg1"  style=" margin:10px 0;   font-size: 1px;
    color: #8c939d;
    width: 178px;
    height: 178px;
     background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;" >
      <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input type="file" name="avatar" style="margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer  " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage1" ref="avatarInput1"  class="uppic">
       </div>

                      
   </div>
                                      <el-form-item label="平台证件编号" :label-width="formLabelWidth">
                                    <el-input v-model="form1.key_2" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="区域选择" :label-width="formLabelWidth">
                                    <el-cascader  :props="props" :options="options"   @change="changeProvinceCity">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.label }}</span>
                                        
                                            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                        </template>
                                        </el-cascader>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia1" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                
                    >
                
               <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
               </el-table-column>
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="jobnumber"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                      <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find(scope.$index, scope.row)">查看</el-button>
                            

                                        <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind1"
                                        width="30%"
                                       >
                                        <p>{{options1}}</p>
                                       
                                        </el-dialog>
                        
                    </template>
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize1}}</p>
                    </template>
                    </el-table-column>


                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit6(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit7(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                </el-table>

                      <el-pagination
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      :current-page="pagination1.page_index"
      :page-sizes="pagination1.page_sizes"
      :page-size="pagination1.page_size"
      :layout="pagination1.layout"
      :total='pagination1.total'
      style="margin-top:20px">
                 </el-pagination>
           </div>
                      



            </div>

           
             
          </el-tab-pane>
            
           

  
            
          
            <el-tab-pane label="调律师" name="seven"><div class="content" style="padding:20px 0;background:#f5f5f5">
             
           <div style="padding:10px;background:#fff">
                 <div style="display:flex;    justify-content: space-between;align-items: center;
                margin-bottom: 15px;">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">列表</p>
       

         

               <el-dialog  title="创建管理员" :visible.sync="dialogFormVisible3">
                <el-form :model="form2">
                    <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input v-model="form2.name" autocomplete="off"></el-input>
                    </el-form-item>
                     <el-form-item label="电话" :label-width="formLabelWidth">
                    <el-input v-model="form2.phone" autocomplete="off"></el-input>
                    </el-form-item>

                       <el-form-item label="身份证号码" :label-width="formLabelWidth">
                    <el-input v-model="form2.id" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="平台证件编号" :label-width="formLabelWidth">
                    <el-input v-model="form2.key_2" autocomplete="off"></el-input>
                    </el-form-item>

                     <el-form-item label="入职时间" :label-width="formLabelWidth">
                                 
                                     <el-date-picker
                                            v-model="form2.worktime"
                                                             format="yyyy 年 MM 月"
                                                                  value-format="yyyy-MM"
                                            type="month"
                                            placeholder="选择月">
                                            </el-date-picker>
                                    </el-form-item>
   
   <div style="display:flex;justify-content: space-between;text-align:center;padding:0 100PX">
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证正面</p>
          
          <img :src="producImg"   style=" margin:10px 0; 
    color: #8c939d;
    width: 178px;
    height: 178px;
      background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;">
    <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input s type="file" name="avatar" style=" margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage" ref="avatarInput"  class="uppic">
       </div>
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证反面</p>
       
          <img :src="producImg1"  style=" margin:10px 0;   font-size: 1px;
    color: #8c939d;
    width: 178px;
    height: 178px;
     background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;" >
      <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input type="file" name="avatar" style="margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer  " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage1" ref="avatarInput1"  class="uppic">
       </div>

                      
   </div>
                                    
         
                  
                </el-form>
                <div slot="footer" class="dialog-footer">
            
                    <el-button type="primary" @click="tianjia5" style="width:100%">添加</el-button>
                </div>
                </el-dialog>
   </div>
                  <el-table
      :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
     <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
               </el-table-column>
   
   
    <el-table-column
      label="姓名"
      prop="name" 
     >
      
    </el-table-column>
 <el-table-column
      label="归属代理商"
      prop="parent" 
     >
    
    </el-table-column>
    <el-table-column
      label="平台等级"
   
     >
     <template slot-scope="scope">
  
               <p>{{scope.row.key_1 | capitalize}}</p>
     </template>
    </el-table-column>
       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
    </el-table-column>

      <el-table-column
        prop="createtime" 
      label="最近登录时间" 
           :show-overflow-tooltip="true" 
      >
    </el-table-column>
   


    

   <el-table-column
     
      label="操作"
   >
     <template slot-scope="scope">

         
       

             <el-button
          size="mini"
            type="primary"
             
           @click="handleEdit55(scope.$index, scope.row)">查看详情</el-button>
        <el-dialog title="投诉订单" :visible.sync="dialogTableVisible222">
        <el-table :data="datas">
            <el-table-column property="name" label="姓名" width="150"></el-table-column>
            <el-table-column property="create_time" label="日期" width="200"></el-table-column>
            <el-table-column property="orderid" label="订单号"></el-table-column>
                <el-table-column property="xzqh" label="地址"></el-table-column>
                    <el-table-column property="reason" label="原因"></el-table-column>
        </el-table>
        </el-dialog>
     <el-dialog title="详情列表" :visible.sync="dialogTableVisible55">
               <div style="display:flex">
                         <div style="width:33%;text-align:center"> <p>一寸个人照片</p>  
                             <viewer :images="zjzpurl">
                         <img @click="dialogTableVisible55 = false" :src=zjzpurl alt="" style="width:200px;height:200px">
                             </viewer>
                         </div> 
                 <div  style="width:33%;text-align:center">   <p>证书照片</p>  
                         <viewer :images="zsurl">
                 <img :src=zsurl @click="dialogTableVisible55 = false" alt="" style="width:200px;height:200px">
                         </viewer>
                 </div> 
                  <div  style="width:33%;text-align:center">  <p>身份证照片</p> 
                    <viewer :images="idurl1">
                   <img :src=idurl1 @click="dialogTableVisible55 = false" alt="" style="width:200px;height:200px">
                    </viewer>
                   </div> 
               </div>
          
                    
                         
                         
   
</el-dialog>

               <!-- <el-button
          size="mini"
            type="info"
            style="margin:5px 0"
           @click="handleEdit44(scope.$index, scope.row)">修改等级</el-button>


           
<el-dialog title="选择等级" :visible.sync="dialogTableVisible44">
  <el-form :model="formk">
   
    <el-form-item label="选择等级" :label-width="formLabelWidth">
      <el-select v-model="formk.region" placeholder="选择等级">
        <el-option label="银牌调律师" value="1"></el-option>
        <el-option label="金牌调律师" value="2"></el-option>
                <el-option label="钻石调律师" value="3"></el-option>
                        <el-option label="区域技术总监" value="4"></el-option>
                                <el-option label="全国技术顾问" value="5"></el-option>
      </el-select>
    </el-form-item>
      <el-button type="primary" @click="sure">确定</el-button>
  </el-form>
</el-dialog>
  <el-button
          size="mini"
            type="info"
             
           @click="handleEdit55(scope.$index, scope.row)">查看详情</el-button>

           <el-dialog title="详情列表" :visible.sync="dialogTableVisible55">
               <div style="display:flex">
                         <div style="width:33%;text-align:center"> <p>一寸个人照片</p>  <img :src=zjzpurl alt="" style="width:200px;height:200px"></div> 
                 <div  style="width:33%;text-align:center">   <p>证书照片</p>  <img :src=zsurl alt="" style="width:200px;height:200px"></div> 
                  <div  style="width:33%;text-align:center">  <p>身份证照片</p>  <img :src=idurl1 alt="" style="width:200px;height:200px"></div> 
               </div>
          
                    
                         
                         
   
</el-dialog> -->
     </template>
    </el-table-column>




      <el-table-column
     
      label="操作"
   >
     <template slot-scope="scope">

         
      
         <el-button
          size="mini"
            type="success"
     
           @click="handleEdit22(scope.$index, scope.row)">五星评价</el-button>


               <el-button
          size="mini"
            type="warning"
            style="margin:5px 0"
           @click="handleEdit44(scope.$index, scope.row)">修改等级</el-button>


           
    <el-dialog title="五星订单管理" :visible.sync="dialogTableVisible333">
               <div style="display:flex;width:100%;justify-content: space-between;">
 <p style="text-align:left;display:block;margin:10px">五星订单数：{{a}} </p>
 <div>
 <el-input-number size="small" @change="handleChange" style="margin-right:20px" v-model="a1" :min="-1000" :max="1000" label="描述文字"></el-input-number>
      <el-button size="small" @click="xiugai1">修改</el-button>
 </div>

              </div>

         <div style="display:flex;width:100%;justify-content: space-between;">
 <p style="text-align:left;display:block;margin:10px">四星订单数：{{b}}  </p>
 <div>
   
     </div>        
           </div>
                 <div style="display:flex;width:100%;justify-content: space-between;">
 <p style="text-align:left;display:block;margin:10px">三星订单数：{{c}}   </p>

         
         </div>
                <div style="display:flex;width:100%;justify-content: space-between;">
 <p style="text-align:left;display:block;margin:10px">二星订单数：{{d}}  </p>
   
           
         </div>
              <div style="display:flex;width:100%;justify-content: space-between;">
 <p style="text-align:left;display:block;margin:10px">一星订单数：{{e}} </p>
   
           </div>
          
                    <el-table :data="datas1">
            <el-table-column property="wechatname" label="姓名" width="150"></el-table-column>
            <el-table-column property="createtime" label="日期" width="200"></el-table-column>
            <el-table-column property="orderid" label="订单号"></el-table-column>
            <el-table-column property="key_2" label="评价等级"></el-table-column>
            <el-table-column property="comment" label="评价内容"></el-table-column>
        </el-table>
        </el-dialog>

<el-dialog title="选择等级" :visible.sync="dialogTableVisible44">
  <el-form :model="formk">
   
    <el-form-item label="选择等级" :label-width="formLabelWidth">
      <el-select v-model="formk.region" placeholder="选择等级">
        <el-option label="银牌调律师" value="A"></el-option>
        <el-option label="金牌调律师" value="B"></el-option>
                <el-option label="钻石调律师" value="C"></el-option>
                        <el-option label="区域技术总监" value="D"></el-option>
                                <el-option label="全国技术顾问" value="E"></el-option>
      </el-select>
    </el-form-item>
      <el-button type="primary" @click="sure">确定</el-button>
  </el-form>
</el-dialog>
          
     </template>
    </el-table-column>
  </el-table>

    <el-pagination
      @size-change="handleSizeChange2"
      @current-change="handleCurrentChange2"
      :current-page="pagination2.page_index"
      :page-sizes="pagination2.page_sizes"
      :page-size="pagination2.page_size"
      :layout="pagination2.layout"
      :total='pagination2.total'
      style="margin-top:20px">
                 </el-pagination>
           </div>
                      



            </div></el-tab-pane>
        
        </el-tabs>
    
       </div> 

            
  </div>
</template>

<script>

export default {
    
    filters: {
  capitalize: function (value) {
  if(value=='A'){
      return '银牌调律师'
    
  }else if(value=='B'){
      return '金牌调律师'
    
  }else if(value=='C'){
      return '钻石调律师'
    
  }else if(value=='D'){
      return '区域技术总监'
    
  }else if(value=='E'){
       return '全国技术顾问'
  }
  },
   capitalize1:function(value){
       if(value == 0){
           return '正常'
       }else{
           return '禁用'
       }
   }
},
 data(){
        return{
              pagination1:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
              pagination2:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
            arrk:'',
                zjzpurl:'',
                zsurl:'',
                idurl1:'',
               form1: {
          name: '',
          phone:'',
          region: '',
          key_2:'',
          id:'',
          worktime:'',
          
       
        },
            a:'',
            b:'',
            c:'',
           d:'',
            e:'',
             a1:0,
            b1:0,
            c1:0,
           d1:0,
            e1:0,
               producImg: '',
          
               producImg1: '',
            formk:{
                region:''
            },
            datas:[],datas1:[],
            dialogTableVisible333:false,
              dialogTableVisible44:false,
                     dialogTableVisible55:false,
            dialogTableVisible222:false,
               props: { multiple: true },
            key1:0,
              tabPosition: '列表',
          
            form2: {
          name: '',
          phone:'',
          region: '',
       
        },
         form3: {
          name: '',
          phone:'',
          region: '',
       
        },
dialogFormVisible3:false,
options:[],
            password:'',
            isactive:0,
            arr1:'',
           dialogFormVisible2:false,
            dialogFormVisible1:false,
            dialogFormVisible33:false,
            dialogFormVisible11:false,
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          date3:[],
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        dialogVisiblefind1:false,
        formLabelWidth: '120px',
        info:[],
        activeName: 'fourth',
        tableData: [],
        tb1:[],
        data1:[],
        data2:[],
        password:'',
        options1:'',
        password2:false,
        password3:'',
        arr:[]
        }
        },
       methods:{



      handleSizeChange1(val) {
this.pagination1.page_size=val


this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination1.page_index,
       limit :val,                      
       roleid :5,
           jobnumber_1:localStorage.getItem('jobnumber') 
       }
        })

     .then(res=>{
    
 this.datall= res.data.data
 
        })
        .catch(function(){
           
        })
  
 


            
                 
      },
      handleCurrentChange1(val) {
       this.pagination1.page_index=val
     


this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
           page :val,
      limit :this.pagination1.page_size,                  
       roleid :5,
           jobnumber_1:localStorage.getItem('jobnumber') 
       }
        })

     .then(res=>{
    
 this.datall= res.data.data
 
        })
        .catch(function(){
           
        })


  
        
      },

  handleSizeChange2(val) {
this.pagination2.page_size=val
 this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_detail_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        page :this.pagination2.page_index,
       limit :val,   jobnumber_1:localStorage.getItem('jobnumber') ,
                           'type':6
                        },
                     
                        }) .then(res=>{
                                this.datall= res.data.data
                        })
    
    



            
                 
      },
      handleCurrentChange2(val) {
       this.pagination2.page_index=val
     

 this.$axios({
                        method: "post",
                        url: "/s/backend/api/cw_detail_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                    page :val,
            limit :this.pagination2.page_size,  jobnumber_1:localStorage.getItem('jobnumber') ,
                           'type':6
                        },
                     
                        }) .then(res=>{
                                this.datall= res.data.data
                        })
    
  
        
      },


           find(index,row){
    this.dialogVisiblefind1 =true
     this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber":  row.jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                               for( var i = 0;i++;i<res.data.data.length){
                                   arr = arr.push(res.data.data[i][0])
                               }
                          
                            })
            .catch(function(){
            
            })  

},
           xiugai1(){


                console.log(this.a1)


              
                if(this.a1<0){
                    var flag ='degrade'

                           this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": -this.a1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                        this.$message({
                        message:'成功',
                        type: 'success'
                            });
                            this.$axios({
                        method: "post",
                        url: "/s/backend/api/bk1_tls_service_comment",
                       params: { 'accessToken':localStorage.getItem('token')},
                        data:{  "jobnumber": this.jobnumber,
                        },
                        }) .then(res=>{
                            this.a =res.data.result.five
                              this.b =res.data.result.four
                                this.c =res.data.result.three
                                  this.d =res.data.result.two
                                   this.e =res.data.result.one
                        }).catch({

                        })
                                    })
                                    .catch(err => {
                                     
                                    })

                }else if(this.a1>0){
                     flag ='plus'
                       this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": this.a1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                          this.$message({
                        message:'成功',
                        type: 'success'
                            });
                            this.$axios({
                        method: "post",
                        url: "/s/backend/api/bk1_tls_service_comment",
                       params: { 'accessToken':localStorage.getItem('token')},
                        data:{  "jobnumber": this.jobnumber,
                        },
                        }) .then(res=>{
                            this.a =res.data.result.five
                              this.b =res.data.result.four
                                this.c =res.data.result.three
                                  this.d =res.data.result.two
                                   this.e =res.data.result.one
                        }).catch({

                        })
                                    })
                                    .catch(err => {
                                     
                                    })
                }else{
                     this.$message({
                        message:'修改值不能和原值相同',
                        type: 'success'
                            });
                }

           },
            xiugai2(){
                     var k = this.b-this.b1;
                if(k>0){
                    var flag ='degrade'

                           this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": this.b1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                        this.$message({
                        message:'成功',
                        type: 'success'
                            });
                                    })
                                    .catch(err => {
                                     
                                    })

                }else if(k<0){
                    var flag ='plus'
                       this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": this.b1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                       
                                    })
                                    .catch(err => {
                                     
                                    })
                }else{
                     this.$message({
                        message:'修改值不能和原值相同',
                        type: 'success'
                            });
                }

           },
            xiugai3(){
                     var k = this.c-this.c1;
                if(k>0){
                    var flag ='degrade'

                           this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": this.c1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                        this.$message({
                        message:'成功',
                        type: 'success'
                            });
                                    })
                                    .catch(err => {
                                     
                                    })

                }else if(k<0){
                    var flag ='plus'
                       this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": this.c1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                       
                                    })
                                    .catch(err => {
                                     
                                    })
                }else{
                     this.$message({
                        message:'修改值不能和原值相同',
                        type: 'success'
                            });
                }

           },
            xiugai4(){
                     var k = this.d-this.d1;
                if(k>0){
                    var flag ='degrade'

                           this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": this.d1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                        this.$message({
                        message:'成功',
                        type: 'success'
                            });
                                    })
                                    .catch(err => {
                                     
                                    })

                }else if(k<0){
                    var flag ='plus'
                       this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": this.d1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                       
                                    })
                                    .catch(err => {
                                     
                                    })
                }else{
                     this.$message({
                        message:'修改值不能和原值相同',
                        type: 'success'
                            });
                }

           },
            xiugai5(){
                     var k = this.e-this.e1;
                if(k>0){
                    var flag ='degrade'

                           this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": this.e1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                        this.$message({
                        message:'成功',
                        type: 'success'
                            });
                                    })
                                    .catch(err => {
                                     
                                    })

                }else if(k<0){
                    var flag ='plus'
                       this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_five_star_number',
                                     params: {
                        accessToken : localStorage.getItem('token')
                        },
                          data:{
                               "count": this.e1,
                            "flag": flag,
                            "jobnumber":   this.jobnumber 
                          }           
                                    })
                                    .then(res => {
                                       
                                    })
                                    .catch(err => {
                                     
                                    })
                }else{
                     this.$message({
                        message:'修改值不能和原值相同',
                        type: 'success'
                            });
                }

           },
           handleChange(value){
             
         this.a1=value
        
           },
             handleChange1(value){
                  this.b1=value
             
           },
             handleChange2(value){
           this.c1=value
             
           },
             handleChange3(value){
            this.d1=value
             
           },
             handleChange4(value){
             this.e1=value
             
           },
                 changeImage(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
         
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg = "http://gqtlgj.cn/" + res.data.key;
                                        console.log(this.producImg)
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
        changeImage1(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
      
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg1 = "http://gqtlgj.cn/" + res.data.key;
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
           sure(){
               this.dialogTableVisible44 = false
               console.log()
               
                     this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/change_tls_degrade',
                                       params: {
                        accessToken : localStorage.getItem('token')
                        },
                                        data:{
                                             "jobnumber": this.jobnumber,
                                              "key_1": this.formk.region
                                        }
                                       
                                    })
                                    .then(res => {
                                      if(res.data.code == 0 ){
                                              this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6 ,
    }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
                                      }

                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })
           },
            tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
           changeProvinceCity(val) { 
               let arr = []
        
                console.log(val)
                for(var i =0;i<val.length;i++){
             arr = arr.concat(val[i][2])
           
                }
            
              this.arr = arr
},
tianjia(){},
    tianjia5(){
  if(this.form2.key_2!=='' && this.form2.phone!=='' && this.form2.name !==''  && this.form2.worktime!=='' &&this.form2.id!==''&&this.producImg !==''&&this.producImg1!==''){
                       console.log()     
                          var d1 =     this.form2.id.substring(5,9)
                                   var d2 =  this.form2.id.substring(10,12)
                                   var d3 = d1+'-'+d2
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "birthday":d3,
                        "zjzpurl":'',
                        "sex":'',
                        "address":'',
                        "worktime":this.form2.worktime,
                        "arealist":[],
                        "id": this.form2.id,
                        "idurl1": this.producImg,
                        "idurl2":this.producImg1,
                        "key_1": "string",
                        "key_2": this.form2.key_2,
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form2.name,
                        "parent": localStorage.getItem('jobnumber'),
                        "password": 123456,
                        "phone": this.form2.phone,
                        "roleid": 6,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                               
                             
                                if(res.data.code==0){
                                       this.dialogFormVisible3=false

                                          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6  }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6  }
        })

     .then(res=>{
  
     this.tableData=data1.concat(res.data.data)
     console.log(this.tableData)
  this.$message({
                        message:'成功',
                        type: 'success'
                            });
        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })
    
                                }else{
                                    this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                }
                        })
        .catch(function(){
           
        })  

  }else{
            this.$message({
                        message:'请填写完整表单',
                        type: 'success'
                            }); 
  }
           },
    tianjia1(){
        
       
             if(this.form1.key_2!=='' && this.form1.phone!=='' && this.form1.name !=='' && this.arr !== '' && this.form1.worktime!=='' &&this.form1.id!==''&&this.producImg !==''&&this.producImg1!==''){
                 console.log()     
                          var d1 =     this.form1.id.substring(5,9)
                                   var d2 =  this.form1.id.substring(10,12)
                                   var d3 = d1+'-'+d2
               this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                             "birthday":d3,
                        "zjzpurl":'',
                        "sex":'',
                        "address":'',
                        "worktime":this.form1.worktime,
                        "arealist": this.arr,
                        "id": this.form1.id,
                          "idurl1": this.producImg,
                        "idurl2":this.producImg1,
                        "key_1": "string",
                        "key_2": this.form1.key_2,
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": localStorage.getItem('jobnumber'),
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid":5,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            this.dialogFormVisible1 = false
                            this.arr= ''
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :5  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }else{
                                     this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            }

                        })
                        
        .catch(function(){
           
        })  
        }else{
             this.$message({
                        message:'请填写完整表单',
                        type: 'success'
                            }); 
        }
           },

  tianjia2(){

            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": this.arr,
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "string",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid": 5,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            this.dialogFormVisible11=false
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :5  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }

                        })
                        
        .catch(function(){
           
        })  
           },

 tianjia3(){

            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": [
                            
                        ],
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "string",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form2.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form2.phone,
                        "roleid": 6,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                this.dialogFormVisible3 = false;
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }

                        })
                        
        .catch(function(){
           
        })  
           },






show2(){this.key1=1

            this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},

show22(){this.key1=1

            this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},


show33(){this.key1=1

            this.$axios.get("/s​/backend​/api​/jszj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},



show44(){this.key1=1

            this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},


show55(){this.key1=1

            this.$axios.get("/s​/backend​/api​/dls_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},
show1(){this.key1=0},
           handleClick111(tab, event) {
          if(tab.name == 'first'){
              this.tab1()
              }
           },
handleClick(tab, event) {
          if(tab.name == 'first'){
              this.tab1()
          }else if( tab.name == 'second')
          {
             this.tab2() 
          }else if( tab.name == 'third')
          {
             this.tab3() 
          }else if( tab.name == 'fourth')
          {
             this.tab4() 
          }else if( tab.name == 'five')
          {
             this.tab5() 
          }else if( tab.name == 'six')
          {
             this.tab6() 
          }else if( tab.name == 'seven')
          {
             this.tab7() 
          }else if( tab.name == 'eight')
          {
             this.tab8() 
          }
      },
        //
         tab1(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2 ,
        jobnumber_1:localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
        tab2(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :11,
        jobnumber_1:localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

   tab3(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :1 ,
        jobnumber_1:localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

 tab4(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :5 ,
        jobnumber_1:localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
          this.tableData = res.data.data
            this.pagination1.total =res.data.count
        })
        .catch(function(){
           
        })
        },
 tab5(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :4  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
 tab6(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :7,
        jobnumber_1:localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

         tab7(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       
       page :1,
       limit :20,
       roleid :6 ,
    
    }
        })

     .then(res=>{
    
         this.tableData = res.data.data
            this.pagination2.total =res.data.count
        })
        .catch(function(){
           
        })
        },

         tab8(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :8 }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
      //
           tiania(){
               console.log(this.form1.region)
            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": [
                            
                        ],
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "string",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid": this.form1.region,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                                 this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                if(res.data.code==0){
                                       this.dialogFormVisible3=false
                                          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :10  }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :9  }
        })

     .then(res=>{
     
     this.tableData=data1.concat(this.data2)

        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })
    
                                }
                        })
        .catch(function(){
           
        })  
           },
          handleEdit(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :2  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
                   handleEdit55(index,row){   
                      this.dialogTableVisible55=true
           

                     this.zjzpurl=row.zjzpurl
                     this.zsurl=row.zsurl
                     this.idurl1=row.idurl1
                   },
          handleEdit44(index,row){this.dialogTableVisible44=true
          this.jobnumber = row.jobnumber},
             handleEdit1(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :2  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },





            handleEdit2(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :11  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit3(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :11  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },








    handleEdit4(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :1  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit5(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :1  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },





    handleEdit6(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :5 ,
                         jobnumber_1:localStorage.getItem('jobnumber')}
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit7(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :5,
                         jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },


    handleEdit8(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :4  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit9(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :4  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },

    handleEdit10(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :7 }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit11(index,row){
                    this.$axios.get("/s/backend/api/complain_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                         }
                            })

                        .then(res=>{
                        
                           
                    
                            })
                            .catch(function(){
                            
                            })
          },


            handleEdit12(index,row){
                this.dialogTableVisible222=true 
               this.$axios.get("/s/backend/api/complain_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                              jobnumber:row.jobnumber
                         }
                            })

                        .then(res=>{
                        
                            this.datas=res.data.data
                        console.log(res.data.data)
                            })
                            .catch(function(){
                            
                            })
        
          },


                 handleEdit22(index,row){
                 

                        this.$axios({
                        method: "post",
                        url: "/s/backend/api/bk1_tls_service_comment",
                       params: { 'accessToken':localStorage.getItem('token')},
                        data:{  "jobnumber": row.jobnumber,
                        },
                        }) .then(res=>{
                            this.a =res.data.result.five
                              this.b =res.data.result.four
                                this.c =res.data.result.three
                                  this.d =res.data.result.two
                                   this.e =res.data.result.one
                        }).catch({

                        })
                     this.jobnumber = row.jobnumber
                this.dialogTableVisible333=true 
             
        
          },
             handleEdit13(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :6  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },

    handleEdit14(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :8  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit15(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :8  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
       },
        mounted(){

                                   
this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })   

this.$axios.get("/s/backend/arealist", {
                        params: { 'jobnumber':localStorage.getItem('jobnumber'),
                     }
                            })

     .then(res=>{
    
  
        this.options = res.data.data
        var arr =[]

        for(var i =0;i<res.data.data[0].children[0].children.length;i++){

            if(i==0){
                arr=res.data.data[0].children[0].children[i].value
            }else{
                arr =res.data.data[0].children[0].children[i].value+','+arr
            }

      
        }
   
          this.arrk = arr
        })
        .catch(function(){
           
        })
            //选择职位
this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :5,
           jobnumber_1:localStorage.getItem('jobnumber') 
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
         this.pagination1.total = res.data.count
 
        })
        .catch(function(){
           
        })
  

      
      
      this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>
.red{
    color: red;
}
/deep/.el-dialog__wrapper{
    top:-50px
}
/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/.kk .el-dialog{
        width: 25%;

    float: right;
  
}

/deep/.el-button+.el-button{
    margin-left: 0px; 
    margin-top: 5px;
}

.actives{
    background: #0034FF  !important;
    color: #fff  !important ;
}

.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
            border-bottom: 1px solid #f5f5f5;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-130%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
            /deep/.el-radio-button__inner{
                padding:8px 15px;
                border-radius: 0;
            }
            /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{


                background: #0034FF;
            }

            .list{
                display: flex;
            }
            .list p {
                padding:8px 15px;
               background: #FFFFFF;font-size: 14px;
border-radius: 4px 0px 0px 0px; cursor: pointer;
            }
            /deep/.cj .el-dialog{
        width: 35% !important;

  height: 80%;
  overflow-y: scroll;
  
}
</style>